import React from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

import { Row, Col } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";

import "./About.scss";

const About = ({ className }) => {
  const { markdownRemark = {} } = useStaticQuery(graphql`
    query AboutQuery {
      markdownRemark(fields: { fileName: { regex: "/about/i" } }) {
        frontmatter {
          anchor
          header
          subheader
          timeline {
            content
            header
            imageContent
            imageFileName
            subheader
          }
        }
      }
    }
  `);

  const frontmatter = markdownRemark.frontmatter;
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        <Col lg={12} offset={4}>
          <div className="px-5 mx-5 mt-5 larger-font">
            <p className="">
              Hi, I am Agastya Jindal, an Electronics engineer and a science geek.
          </p>
            <p className="">
              I am ten years old
              and this is my company: EDGETEK
          </p>
            <p className="">
              My dream is to make this world a better place with more
              advancements then ever before.
          </p>
            <p className="text-right">Agastya Jindal</p>
          </div>
        </Col>
      </Row>
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
};

About.defaultProps = {
  className: null,
};

export default About;
