import React from "react";
import PropTypes from "prop-types";

import { useStaticQuery, graphql } from "gatsby";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";

const Contact = ({ className }) => {
  const { markdownRemark = {} } = useStaticQuery(graphql`
    query ContactQuery {
      markdownRemark(fields: { fileName: { regex: "/contact/i" } }) {
        frontmatter {
          anchor
          header
          subheader
          telephone
          email
        }
      }
    }
  `);

  const frontmatter = markdownRemark.frontmatter;
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row className="">
        <Col md={{ span: 8, offset: 2 }} >
          <form name="edgetek-contact" method="POST" data-netlify-honeypot="_gotcha" data-netlify="true">
            <input type="hidden" name="form-name" value="edgetek-contact" />
            <input type="hidden" name="_subject" value="EdgeTek Website Contact Request" />
            <div className="form-group row">
              <div className="col-md-4">
                <label className=" col-form-label font-weight-bold label-required" htmlFor="name">
                  Name
                        <small className="form-text text-muted">Required</small>
                </label>
              </div>
              <div className="col-md-8">
                <input name="name" type="text" className="form-control" id="name" placeholder="Name" required="" />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 col-form-label font-weight-bold label-required" htmlFor="email">
                Email
                    <small className="form-text text-muted">Required</small>
              </label>
              <div className="col-md-8">
                <input name="email" type="email" className="form-control" id="email" placeholder="Email" required="" />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 col-form-label font-weight-bold label-required" htmlFor="phone">
                Phone
                </label>
              <div className="col-md-8">
                <input name="phone" type="text" className="form-control" id="phone" placeholder="Phone" />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-md-4 col-form-label font-weight-bold label-required" htmlFor="company">
                Company
                    <small className="form-text text-muted">Required</small>
              </label>
              <div className="col-md-8">
                <input name="company" type="text" className="form-control" id="company" placeholder="Company" required="" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message" className="col-form-label font-weight-bold">Message</label>
              <textarea name="message" className="form-control" id="message" placeholder="Message" />
            </div>
            <input type="hidden" name="_format" value="plain" />
            <input type="text" className="d-none" name="_gotcha" />
            <div data-netlify-recaptcha="" />
            <div className="text-right">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </Col>
      </Row>
    </PageSection >
  );
};

Contact.propTypes = {
  className: PropTypes.string,
};

Contact.defaultProps = {
  className: null,
};

export default Contact;
