import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { useStaticQuery, graphql } from "gatsby";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PortfolioItem from "components/PortfolioItem";
import PageSection from "components/PageSection";
import "./Portfolio.scss";

const Portfolio = ({ className }) => {
  const { markdownRemark = {} } = useStaticQuery(graphql`
    query PortfolioQuery {
      markdownRemark(fields: { fileName: { regex: "/portfolio/i" } }) {
        frontmatter {
          anchor
          header
          subheader
          portfolios {
            content
            extraInfo
            header
            imageFileName
            imageFileNameDetail
            subheader
          }
        }
      }
    }
  `);

  const frontmatter = markdownRemark.frontmatter;
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, portfolios } = frontmatter;

  return (
    <PageSection className={clsx("portfolio-section", className)} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row>
        {portfolios.map(
          ({ content, extraInfo, header, imageFileName, imageFileNameDetail, subheader }) => (
            <PortfolioItem
              key={header}
              imageFileName={imageFileName}
              header={header}
              subheader={subheader}
              content={content}
              imageFileNameDetail={imageFileNameDetail}
              extraInfo={
                <ul>
                  {extraInfo.map((ei) => (
                    <li key={ei}>{ei}</li>
                  ))}
                </ul>
              }
            />
          ),
        )}
      </Row>
      <p> To buy and EDGETEK products do this:
      1. First choose your products from this website and make up your mind
      2. Then fill out this form:
      <a href="https://docs.google.com/forms/d/1ZXd2iUW92GmJIgCLPfbNEu_kvd_HCZBamEofnEhYn00/edit"> Form </a>
    click on the link above to go to fill this form and then make sure that is is filled to your choice
    3. Then contact us and tell you have filed out the form throught our website
    4. Now when you are ready to buy and we will check how you would like to recive the product if you chose
    mail then tell us your addres we&apos;ll mail it out to you and if you choose pickup then we will tell our addres
    of our office and please come to pick up and remember mailing costs will be charged.</p>
    </PageSection>
  );
};

Portfolio.propTypes = {
  className: PropTypes.string,
};

Portfolio.defaultProps = {
  className: null,
};

export default Portfolio;
